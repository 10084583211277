<script setup>
const contactsItem = [
    {
        icon: '/icon-phone.svg',
        alt: 'Телефон',
        description: '<a href="tel:+74951430202">+7&nbsp;495&nbsp;143&nbsp;02&nbsp;02</a>'
    },
    {
        icon: '/footer-block-mail.svg',
        alt: 'Email',
        description: '<a href="malito:info@air-arts.ru">info@air-arts.ru</a>'
    },
    {
        icon: '/vk.svg',
        alt: 'VK',
        description: '<a href="https://vk.com/air__arts" target="_blank">Air Arts в VK</a>'
    },
    {
        icon: '/telegram.svg',
        alt: 'Telegram',
        description: '<a href="https://t.me/air_arts" target="_blank">Air Arts в Telegram</a>'
    },
    {
        icon: '/footer-block-calendar.svg',
        alt: 'Режим работы',
        description: 'Пн - Пт: 10:00 - 18:00<br>Сб, Вс: выходной'
    },
    {
        icon: '/icon-place.svg',
        alt: 'Адрес',
        description: 'г. Щёлково, ул. Беляева 7а,<br>Московская область'
    },
];
</script>

<template>
    <div class="contacts-medium">
        <h2 class="contacts-medium__title">Контакты</h2>
        <div v-for="(item, index) in contactsItem" :key="item.icon" class="contacts-medium__item">
            <NuxtImg :src="item.icon" :alt="item.alt" :title="`Изображение ${item.alt}`" class="contacts-medium__icon" loading="lazy"
                :class="[`contacts-medium__icon_${index}`]" />
            <div class="contacts-medium__description" v-html="item.description"></div>
        </div>
        <a href="https://www.liveinternet.ru/click" target="_blank"><img id="licntA831" width="88" height="15"
                style="border:0" title="LiveInternet: показано число посетителей за сегодня"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
                alt="LiveInternet: показано число посетителей за сегодня" />
        </a>
    </div>
</template>

<style lang="less">
.contacts-medium {
    width: 190px;
    margin-top: 80px;

    &__title {
        font-family: 'Ruberoid';
        font-size: 26px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin-bottom: 20px;
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(190, 225, 194, 1);

        &:last-of-type {
            border-bottom: unset;
        }
    }

    &__icon {
        margin-right: 15px;

        &_0 {
            width: 22px;
            height: 22px;
        }

        &_1 {
            width: 22px;
            height: 22px;
        }

        &_2 {
            width: 24px;
            height: 24px;
        }

        &_3 {
            width: 24px;
            height: 24px;
        }

        &_4 {
            width: 22px;
            height: 22px;
        }

        &_5 {
            width: 22px;
            height: 28px;
        }
    }

    &__description {
        font-family: 'Proxima Nova Rg';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(123, 137, 125, 1);
    }

    a {
        color: rgba(123, 137, 125, 1);
        text-decoration: none;
    }
}
</style>
